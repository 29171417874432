body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  padding-left: 16px;
}

.simple-form > div {
  overflow: scroll;
}

.page-number:not(.MuiButton-textPrimary) {
  color: rgba(0, 0, 0, 0.54);
}

.edit-page {
  max-width: calc(100vw - 30px);
}

.edit-page .simple-form > .MuiCardContent-root {
  flex: 1;
}

.MuiDrawer-root {
  display: none;
}
.MuiPaper-root > .MuiToolbar-root {
  padding-right: 0px !important;
}

.MuiDrawer-root + div {
  padding-left: 16px;
}

.MuiPaper-root > .MuiToolbar-root > button:first-child {
  display: none;
}
